import '../styles/Home.css';
import {Button, Stack} from '@mui/material';

const Home = () => {
  return (
    <div className='Container' >
      <div className='titleContainer'>
        <span className='playfair-display mainTitle'>Dream<i>ing</i></span>
        <span className='playfair-display mainTitle'>Bigger</span>
      </div>

      <div className='secondaryTitleContainer'>
        <span className='questrial secondaryTitle'>Your <i className='playfair-display text-emphasis text-gradient'>dream</i></span><br />
        <span className='questrial secondaryTitle'>begins now</span>
      </div>

      <div className='mainContent'>
        <p className='poppins' style={{color:'#411A77', lineHeight: 1.2}}>
          Here, you'll not only manifest your own future but also carve its path.
          As a member of our platform, you will be a part of a community of strong,
          capable women dedicated to achieving goals and supporting each other in their corporate pursuits.
        </p>
        <span className='poppins' style={{color:'#411A77', lineHeight: 1.3 }}>Embrace the journey, empower yourself, and prepare for something <span className='dm-serif-display' style={{fontSize: 18}}>spectacular!</span> .</span>
      </div>

      <div className='buttonContainer'>
        <button 
          className='registerButton'
          onClick={() => window.open('https://dreambigventuresllc.com/#signup', '_blank')}  
        >
          Join our waitlist
        </button>
      </div>
    </div>
  )
};

export default Home;